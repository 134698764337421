<template>
  <Detail id='' :is-edit="false"/>
</template>

<script>
import Detail from './components/Detail'

export default {
  name: 'CreateEnterprise',
  components: { Detail }
}
</script>

